import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button, Image, Ratio, Dropdown, Form} from 'react-bootstrap';
import {PinAngle, ChevronDown, Filter} from 'react-bootstrap-icons';
import GoBack from '../GoBack.js';
import axios from 'axios';
import ClassView from './ClassView.js';
import styled from 'styled-components';


const fontSizeSmall={fontSize: '0.6rem', width: '100%', marginRight:'0', padding:0};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '1px solid #999999'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px', width:'38%', paddingRight: 0};
const fontSmall = {fontSize:'0.6rem', padding: '3px'};
const marginTop2 = {marginTop: '2px', height: '48%'};

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none !important; // 화살표 숨기기
  }
`;
const fontSize08 = {fontSize:'0.8rem'};

const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedMonth}월 ${formattedDay}일 ${formattedHours}:${formattedMinutes}`;
};

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

function checkType(MyType){
        let category = 0;

        switch(MyType) {
                case 'Amity': category = "/ClassAmity.png"; break;
                case 'Exercise': category = "/ClassExercise.png"; break;
                case 'Hobby': category = "/ClassHobby.png"; break;
                case 'Etc': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;

        }

        return category;
}


function LocationClassAll() { 

    const [myClassList, setMyClassList] = useState([]);
    const [page, setPage] = useState(0);
    const [dataCheck, setDataCheck] = useState(false);
    const [myUid, setMyUid] = useState('');
    const [filterType, setFilterType] = useState('default');
    const [checked, setChecked] = useState(false);

    useEffect(() => {
	console.log('here');

	const fetchData = async () => {
	    try{
		const response = await axios.post(`${apiUrl}/myLocationClassAll`, { myToken: token, pageNum : page, filterType: filterType, checked: checked });
		if(response.data){
			setMyClassList(prevMyClassList => [...prevMyClassList, ...response.data.myClassData]);
			setDataCheck(true);
			console.log('response Data :', response.data);
			setMyUid(response.data.myUid);
		}else{
			console.error('No data returned of not an array;');
		}
	    }catch(error){
		console.error('1-2 : Error fetching data: ', error);
	    }
	};
	fetchData();

    },[page, filterType, checked]);


const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, i) => {
        if (i % size === 0) acc.push(arr.slice(i, i + size));
        return acc;
    }, []);
};

const ViewMyClassList = chunkArray(myClassList, 3).map((group, rowIndex) => (
    <Row key={rowIndex} style={{ overflowX: 'auto', flexWrap: 'nowrap', whiteSpace: 'nowrap', marginBottom: '10px' }}> 
        {group.map((v, index) => (
            <Col xs={5} key={index} style={classAll} onClick={() => handleViewClick(v.classId)}>
                <div className="text-center" style={fontSmall}></div>
                <Row style={{ margin: 0, marginRight: '10px' }}> 
                    <Row style={{ margin: 0 }}></Row>
                    <Row style={{ ...classBody, margin: 0 }}> 
                        {myUid === v.cOpenUser && (
                            <div style={{ position: 'relative' }}> 
                                <img
                                    src='/crown.png'
                                    alt='Crown Icon'
                                    style={{ position: 'absolute', right: '5px', top: '5px', zIndex: 1, width: '15px', height: '15px' }}
                                />  
                            </div>
                        )}  
                        <Ratio aspectRatio="1x1" style={marginTop2}>
                            <Image src={checkType(v.ctype)} fluid rounded style={{ border: '1px solid', width: '50%', height: 'auto', margin: '0.5rem 0 0 0.5rem' }} />
                        </Ratio>
                        <div style={{ fontWeight: 'bold', textAlign: 'left', padding: '0 5px', fontSize: '12px', whiteSpace: 'normal' }}>{v.cTitle}</div>
                        <div style={{ textAlign: 'left', padding: '0 2px' }}> 
                            <Image style={{ width: '15px' }} src='/userIcon.png' /> {v.cNowUser.length + 1}/{v.cTotalNum}
                        </div>
                        <div style={{ color: 'gray', textAlign: 'left', padding: '0 2px', margin: '10px 0' }}>{formatDate(v.cOpenDate)}</div>
                    </Row>
                </Row>
            </Col>
        ))} 
    </Row>
));


        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

        const handleCloseView = () => {
                setViewClassCheck(!viewClassCheck);
        };

	const handleMoreClass = () => {
		setPage(page+1);
		console.log('page :', page);
	};

	const [filterText, setFilterText] = useState('정렬방법 선택');
	const handleFilter = async(filter) => {
		switch(filter){
			case 'near' :
				setFilterText('가까운 일정보기')
				setFilterType(filter);
				setMyClassList([]);
				break;
			case 'far' :
				setFilterText('먼 일정보기');
				setFilterType(filter);
				setMyClassList([]);
				break;
			default :
				setFilterText('정렬방법 선택');
				setFilterType(filter);
				break;
		}
	};

	const handleCheckboxChange = (e) => {
		setChecked(e.target.checked);
		console.log('checkbox is now : ', e.target.checked);
		setMyClassList([]);
	};

	return(
	<div style={{marginBottom: '40px', paddingTop: '20px'}}>
	<GoBack />
	<Container>
	<Row>
		<h3><b>우리동네 모임</b></h3>
	</Row>
	<Row style={{marginBottom: '30px'}}>
	    <Col style={{textAlign: 'left', fontSize: '0.8rem', paddingTop: '7px'}}>
		<Form.Check id="my-checkbox" type="checkbox" checked={checked} onChange={handleCheckboxChange} label='내 모임보기' />
	    </Col>
	    <Col style={{textAlign: 'right'}}>
	    <Dropdown>
		<Dropdown.Toggle variant="outline" id="dropdown-basic" style={fontSize08}>
		    <Filter /> {filterText}
		</Dropdown.Toggle>

		<Dropdown.Menu>
		    <Dropdown.Item href="#/action-1" style={fontSize08} onClick={()=>handleFilter('near')}>가까운 일정보기</Dropdown.Item>
		    <Dropdown.Item href="#/action-2" style={fontSize08} onClick={()=>handleFilter('far')}>먼 일정보기</Dropdown.Item>
		</Dropdown.Menu>
	    </Dropdown>
	    </Col>
	</Row>
	    <div>
		<Row className={fontSizeSmall}>
		    {dataCheck && ViewMyClassList}
		</Row>
	    </div>
	    <Button variant='outlined' style={{color: 'black', fontSize: '0.8rem', fontWeight: 'bold', width:'60%'}} onClick={()=>handleMoreClass()}> 더보기 <ChevronDown /> </Button> 
	</Container>
        {viewClassCheck && (
                <div>
                    <ClassView classId={viewClassId} onCloseView={handleCloseView}/>
                </div>
        )}

	</div>
	);
}

export default LocationClassAll;
