import React, { useState } from 'react';
import axios from 'axios';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import { getStatusLabel, formatSignUpTime, formatPhoneNumber, changeUserState } from './utils';


function AdminUserFind() {
  const [searchCriteria, setSearchCriteria] = useState({ uid: '', nname: '' });
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // 선택된 유저 정보
  const [showModal, setShowModal] = useState(false); // 모달 상태
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria({ ...searchCriteria, [name]: value });
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post(`${apiUrl}/findUsers`, { 
	      searchCriteria,
	      token,
      });
      setUserData(response.data.users);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };


  return (
    <div style={{ marginTop: '20px' }}>
      <h3>사용자 상태 변경</h3>
      <Form style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <Form.Control
          type="text"
          name="uid"
          placeholder="UID"
          value={searchCriteria.uid}
          onChange={handleInputChange}
          style={{ fontSize: '0.8rem', width: '20%' }}
        />
        <Form.Control
          type="text"
          name="nname"
          placeholder="닉네임"
          value={searchCriteria.nname}
          onChange={handleInputChange}
          style={{ fontSize: '0.8rem', width: '20%' }}
        />
        <Button onClick={handleSearch} variant="primary" style={{ fontSize: '0.8rem' }}>
          검색
        </Button>
      </Form>
      <Table striped bordered hover style={{ fontSize: '0.6rem' }}>
        <thead>
          <tr>
            <th>UID</th>
            <th>생일</th>
            <th>성별</th>
            <th>닉네임</th>
            <th>메시지</th>
            <th>가입시간</th>
            <th>핸드폰</th>
            <th>이름</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user) => (
            <tr key={user.uid} onClick={() => handleRowClick(user)} style={{ cursor: 'pointer' }}>
              <td>{user.uid}</td>
              <td>{user.birth}</td>
              <td>{user.sex === 'F' ? '여성' : '남성'}</td>
              <td>{user.nname}</td>
              <td>{user.message}</td>
              <td>{new Date(user.time).toLocaleString()}</td>
              <td>{user.phone}</td>
              <td>{user.rname}</td>
              <td>{getStatusLabel(user.state)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* 모달 */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>유저 상태 변경</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{fontSize: '0.8rem'}}>
          {selectedUser && (
            <div>
              <p>
                <strong>UID:</strong> {selectedUser.uid}
              </p>
              <p>
                <strong>닉네임:</strong> {selectedUser.nname}
              </p>
              <p>
                <strong>상태:</strong> {getStatusLabel(selectedUser.state)}
              </p>
	      <hr />
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <Button variant="danger" style={{fontSize: '0.8rem'}} onClick={() => changeUserState(selectedUser.uid, "B")}>
                  정지
                </Button>
                <Button variant="warning" style={{fontSize: '0.8rem'}} onClick={() => changeUserState(selectedUser.uid, "W")}>
                  경고
                </Button>
                <Button variant="success" style={{fontSize: '0.8rem'}} onClick={() => changeUserState(selectedUser.uid, "Y")}>
                  정상
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminUserFind;

