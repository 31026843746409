import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import {CameraFill, PinMapFill} from 'react-bootstrap-icons';
function LoginAgree({ onNextClick }) { // 부모 컴포넌트에서 전달된 콜백 함수 받기
  const [allChecked, setAllChecked] = useState(false);
  const [agreements, setAgreements] = useState({
    service: false,
    personalInfo: false,
    location: false,
    marketing: false,
  });
  const [documentContent, setDocumentContent] = useState('');
  const [selectedDocument, setSelectedDocument] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleAllCheckedChange = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);
    setAgreements({
      service: newCheckedState,
      personalInfo: newCheckedState,
      location: newCheckedState,
      marketing: newCheckedState,
    });
  };

  const handleAgreementChange = (key) => {
    const updatedAgreements = {
      ...agreements,
      [key]: !agreements[key],
    };
    setAgreements(updatedAgreements);
    setAllChecked(Object.values(updatedAgreements).every((value) => value));
  };

  const handleAgreeCheck = async (key) => {
    setSelectedDocument(key);
    try {
      const response = await fetch(`./agreeDocument/${key}.html`);
      const text = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      const bodyContent = doc.body.innerHTML;  // <body> 내용만 추출
      console.log('Fetched content:', bodyContent);  // 가져온 내용 확인
      setDocumentContent(bodyContent);
      setShowModal(true); // 모달 열기
    } catch (error) {
      console.error('Error loading document:', error);
      setDocumentContent('<p>문서를 불러올 수 없습니다.</p>');
      setShowModal(true); // 모달 열기
    }
  };

  const handleCloseModal = () => setShowModal(false); // 모달 닫기

  // 필수 항목 체크 확인 함수
  const areRequiredChecked = () => {
    return agreements.service && agreements.personalInfo && agreements.location;
  };

  // "다음" 버튼 클릭 핸들러
  const handleNextClick = () => {
    if (areRequiredChecked()) {
      onNextClick(); // 부모 컴포넌트로부터 전달된 콜백 함수 호출
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '30px', color: '#ffa015' }}>INNERDONG</div>
      <h2 style={styles.title}>약관 동의</h2>
      <p style={styles.subtitle}>INNERDONG 사용을 위해선 약관 동의가 필요해요.</p>

      <div style={styles.allAgreeContainer}>
        <input
          type="checkbox"
          id="allChecked"
          checked={allChecked}
          onChange={handleAllCheckedChange}
        />
        <label htmlFor="allChecked" style={styles.allAgreeLabel}>약관 전체동의</label>
      </div>

      <div style={styles.agreementList}>
        <p style={styles.notice}>
          유의사항
          <br />
          * INNERDONG은 휴대폰을 보유한 내국인만 이용 가능해요.<br />
	  * INNERDONG은 17세 이상만 이용이 가능해요.
        </p>
        <div style={styles.agreementItem}>
          <Row style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="service"
                checked={agreements.service}
                onChange={() => handleAgreementChange('service')}
              />
            </Col>
            <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="service">(필수) 서비스 이용약관</label>
            </Col>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Button onClick={() => handleAgreeCheck('privacy')} variant='light' style={{ fontSize: '0.7rem' }}>보기</Button>
            </Col>
          </Row>
        </div>
        <div style={styles.agreementItem}>
          <Row style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="personalInfo"
                checked={agreements.personalInfo}
                onChange={() => handleAgreementChange('personalInfo')}
              />
            </Col>
            <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="personalInfo">(필수) 개인정보 수집 이용 동의</label>
            </Col>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Button onClick={() => handleAgreeCheck('privacy_agree')} variant='light' style={{ fontSize: '0.7rem' }}>보기</Button>
            </Col>
          </Row>
        </div>
        <div style={styles.agreementItem}>
          <Row style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="location"
                checked={agreements.location}
                onChange={() => handleAgreementChange('location')}
              />
            </Col>
            <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="location">(필수) 위치정보 수집 이용 동의</label>
            </Col>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Button onClick={() => handleAgreeCheck('location_agree')} variant='light' style={{ fontSize: '0.7rem' }}>보기</Button>
            </Col>
          </Row>
        </div>
        <div style={styles.agreementItem}>
          <Row style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="marketing"
                checked={agreements.marketing}
                onChange={() => handleAgreementChange('marketing')}
              />
            </Col>
            <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="marketing">(선택) 마케팅 이용 동의</label>
            </Col>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Button onClick={() => handleAgreeCheck('marketing')} variant='light' style={{ fontSize: '0.7rem' }}>보기</Button>
            </Col>
          </Row>
	</div>
	  <hr />
	<div style={{...styles.agreementItem, display:'block'}}>
	  <div>
	  <Row style={{padding: '0 5%', marginTop: '1rem'}}>
	      <Col xs={3} style={{padding:0, textAlign: 'center'}}>
	  	<PinMapFill size={40} style={{color: '#b0a18b'}}/>
	      </Col>
	      <Col style={{display: 'flex', alignItems: 'center'}}>
	  	위치정보 접근권한은 채팅방 가입과 주변 모임을 소개하는데 사용돼요.
	      </Col>
	  </Row>
	  </div>
	  <div style={{marginTop: '1rem'}}>
	  <Row style={{padding: '0 5%', marginTop: '1rem'}}>
	      <Col xs={3} style={{padding:0, textAlign: 'center'}}>
	  	<CameraFill size={40} style={{color: '#b0a18b'}}/>
	      </Col>
	      <Col style={{display: 'flex', alignItems: 'center'}}>
	  	카메라 접근 권한은 프로필 사진 변경과 채팅방 내에서 사용돼요.
	     </Col>
	  </Row>
	  </div>
        </div>
      </div>

      <button
        style={{
          ...styles.nextButton,
          backgroundColor: areRequiredChecked() ? '#ef891b' : '#686868', // 조건부 스타일링
        }}
        disabled={!areRequiredChecked()}  // 필수 항목이 모두 체크되었을 때만 활성화
        onClick={handleNextClick} // "다음" 버튼 클릭 시 호출
      >
        다음
      </button>

      {/* 모달 컴포넌트 */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{textAlign:'center', width:'100%'}}>약관 내용</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {parse(documentContent)}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    height: '90vh',
    padding: '20px',
    backgroundColor: '#fff',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  allAgreeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  allAgreeLabel: {
    fontSize: '18px',
    marginLeft: '10px',
    fontWeight: 'bold',
  },
  agreementList: {
    width: '100%',
    maxWidth: '400px',
    textAlign: 'left',
  },
  notice: {
    fontSize: '12px',
    color: '#888',
    marginBottom: '10px',
  },
  agreementItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  nextButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '18px',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default LoginAgree;

