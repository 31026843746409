import {Container, Row, Col, Button, Image, Ratio, Modal} from 'react-bootstrap';
import {CursorFill} from 'react-bootstrap-icons';
import React, {useState, useEffect, useRef} from 'react';
import {isEqual} from 'lodash';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import Loading from '../LoadingDefault';

const fontSizeSmall={width: '100%'};

function ConfirmLocation(props) {
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  const [paramsLo, setParamsLo] = useState('myLocation');
  const paramsLoRef = useRef(paramsLo); // 최신 상태값 참조
  const [myInfo, setData] = useState(null);
  const [nowLocation, setNowLo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [tempLocation, setTempLocation] = useState(false);
  const handleClose = () => setShowModal(false);

  // 상태 업데이트를 중복되지 않도록 방지
  useEffect(() => {
    const tempParamsLo = props.paramslo;
    const newParamsLo = isEqual(tempParamsLo, 'oftenLocation') ? 'oftenLocation' : 'myLocation';

    // 상태 업데이트가 중복되지 않도록 조건 추가
    if (paramsLo !== newParamsLo) {
      setParamsLo(newParamsLo);
      paramsLoRef.current = newParamsLo; // useRef로도 최신값 유지
    }
    console.log('paramsLo updated to: ', paramsLo);
  }, [props.paramslo]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(`${apiUrl}/ConfirmLocation`, { myToken: token });
      setData(response.data);

      const myLocation = response.data.loginUser.mainlo;
      const oftenLocation = response.data.loginUser.sublo;

      // 현재 상태를 기반으로 위치 설정
      setNowLo(isEqual(paramsLoRef.current, 'oftenLocation') ? oftenLocation : myLocation);
      console.log('#####paramsLo: ', paramsLoRef.current);
    };

    // paramsLo가 변경되었을 때에만 데이터를 가져옴
    fetchData();
  }, [paramsLo, token]);  // paramsLo가 변경될 때마다 실행되므로 중복 호출되지 않음

  const requestLocation = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      //window.ReactNativeWebView.postMessage('getLocation');
      window.ReactNativeWebView.postMessage(
	      JSON.stringify({
		      action: 'getLocation'
	      })
      );
      setTempLocation(true);
    } else {
      console.error('ReactNativeWebView is not defined.');
      alert('This function can only be used within the WebView.');
    }
  };

  const receiveLocation = async (event) => {
    try {
      const locationData = JSON.parse(event.data);
      const { latitude, longitude } = locationData;

      const response = await axios.post(`${apiUrl}/GeoCode`, {
        latitude,
        longitude,
        myToken: token,
        paramsLo: paramsLoRef.current, // 최신 paramsLo 값 사용
      });

      switch (response.data.res) {
        case 'Y':
          setNowLo(response.data.fullAddress);
          localStorage.setItem('token', token);
          setModalMessage(response.data.message);
          setShowModal(true);
	  setTempLocation(false);
          break;

        case 'SP':
          setModalMessage(response.data.message);
          setShowModal(true);
	  setTempLocation(false);
          break;

        default:
          console.warn('Unknown response code:', response.data.res);
	  alert('오류가 발생하였습니다. 관리자에게 문의해주세요.');
          break;
      }
    } catch (error) {
      console.error('Failed to parse location data:', error);
    }
  };

  useEffect(() => {
    const messageHandler = (event) => {
      receiveLocation(event);
    };

    // 이벤트 리스너 중복 등록 방지
    window.addEventListener('message', messageHandler);
    document.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
      document.removeEventListener('message', messageHandler);
    };
  }, []);  // 이벤트 리스너는 컴포넌트 마운트 시에만 등록


  const getButtonStyle = (isActive) => ({
    backgroundColor: isActive ? '#FFDE59' : 'white',
    border: isActive ? '0' : '1px solid #DDDDDD',
    color: isActive ? 'black' : '#a1a1a1',
    borderRadius: '1rem',
    fontWeight: 'bold',
    textDecoration: 'none',
  });



  return (
  <div>
    <div style={{padding: '10px', paddingTop: '20px'}}>
      <Modal show={showModal} centered>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer style={{ textAlign: 'center', display: 'block' }}>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
      <h3><b>동네인증</b></h3>
      <Row className={fontSizeSmall} style={{margin:0, marginTop: '1rem'}}>
        <Col xs={12}>
          <Link to="/location/myLocation" style={{marginRight: '10px'}}>
            <Button variant="link" style={getButtonStyle(isEqual(paramsLo, 'myLocation'))} >우리동네</Button>
          </Link>
          <Link to="/location/oftenLocation">
            <Button variant="link" style={getButtonStyle(isEqual(paramsLo, 'oftenLocation'))}  >자주가는동네</Button>
          </Link>
        </Col>
      </Row>


    <div style={styles.container}>
      {/* 배경 이미지 */}
        <div style={styles.content}>
	  <p style={{marginBottom: '0.5rem', color: '#5c29d2'}}>
	      지금은 <b style={{textDecoration: 'underline'}}>서울시 관악구</b> 에서만 이용이 가능해요
	  </p>
          <div style={styles.textContainer}>
             <Image src='/markIcon.png' style={{color: nowLocation ? undefined : 'orange'}}/> 
	  	{nowLocation ? nowLocation : <b style={{ color: 'orange' }}>  위치인증 필요</b>}
          </div>
          <Button
            variant="warning"
            style={{ marginLeft: '0', fontSize: '0.8rem', fontWeight: 500, backgroundColor: '#773FFB', color: 'white', border: 0 }}
            onClick={requestLocation}
          >
            <CursorFill />현재위치 공유
          </Button>
        </div>
      </div>
    </div>
      {/* 로딩 스피너 */}
      <Loading loading={tempLocation}/>
  </div>
  );
}

// 스타일 정의
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    margin: '0.6rem',
    marginTop: '1rem',
    backgroundColor: '#f1f1f1',
    borderRadius: '0.8rem',
  },
  backgroundContainer: {
    position: 'relative',
    width: '100%',
    height: '95%',
    backgroundPosition: 'center', // 이미지가 중앙에 위치
    backgroundRepeat: 'no-repeat', // 이미지 반복을 방지
    backgroundSize: 'cover',
    opacity: '0.5',
    marginTop: '10px',
    borderRadius: '0.7rem',
  },
  content: {
    position: 'absolute',
    textAlign: 'center',
    zIndex: 1, // 배경 위에 텍스트 및 버튼 배치
  },
  textContainer: {
    padding: '10px 15px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
    width: '18rem',
    backgroundColor: '#ffffffc7',
  },
  button: {
    padding: '5px 10px',
    fontSize: '14px',
  },
};


export default ConfirmLocation;

