import { useState } from "react";
import axios from "axios";
import { Form, Button, Modal } from 'react-bootstrap';

const AdminPartnerInput = () => {
  const [partnerData, setPartnerData] = useState({
    pName: "",
    pPhone: "",
    pEmail: "",
    pNume: "",
  });

  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartnerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token"); // 인증 토큰 (필요 시)

      const response = await axios.post(`${apiUrl}/AdminPartnerInput`, {
        ...partnerData,
        token, // 필요 없으면 삭제 가능
      });
      
      console.log("Success:", response.data);
      alert("파트너 정보가 성공적으로 등록되었습니다!");
    } catch (error) {
      console.error("Error:", error);
      alert("파트너 등록 중 오류가 발생했습니다.");
    }
  };

const [view, setView] = useState(false);
const handleView = () => {
	setView(!view);
};

  return (
<div style={{textAlign: 'left'}}>
   <Button variant="success" onClick={() => handleView()}> 파트너 등록 화면 {view ? "닫기" : "열기"}</Button>
   <hr />
{view && (
  <>
<Modal show={view} onHide={handleView}>
  <Modal.Header closeButton>
    <Modal.Title>파트너 정보 등록</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{textAlign: 'center'}}>
    <div style={{display:'inline-block', width: '80%', maxWidth: '500px'}}>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>파트너 이름</Form.Label>
          <Form.Control
            type="text"
            name="pName"
            placeholder="파트너 이름"
            value={partnerData.pName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>파트너 핸드폰</Form.Label>
          <Form.Control
            type="number"
            name="pPhone"
            placeholder="파트너 핸드폰"
            value={partnerData.pPhone}
            onChange={handleChange}
            min="0"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>파트너 이메일</Form.Label>
          <Form.Control
            type="email"
            name="pEmail"
            placeholder="파트너 이메일"
            value={partnerData.pEmail}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>파트너 사업자번호</Form.Label>
          <Form.Control
            type="number"
            name="pNume"
            placeholder="파트너 사업자번호"
            value={partnerData.pNume}
            onChange={handleChange}
            min="0"
          />
      </Form.Group>
	<br />
        <Button variant="outline-success" type="submit">
          파트너 등록
        </Button>
	<br />
      </Form>
  </div>
  </Modal.Body>
</Modal>
 </>
)}
</div>
  );
};

export default AdminPartnerInput;

