import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { List, Person, House, Search } from 'react-bootstrap-icons';
import AdminUserCheck from './AdminUserCheck';
import AdminUserFind from './AdminUserFind';
import AdminReportCheck from './AdminReportCheck';
import AdminStopUser from './AdminStopUser';
import AdminDeleteUser from './AdminDeleteUser';
import AdminPartnerInput from './AdminPartnerInput';
import AdminPartnerList from './AdminPartnerList';
import AdminAdList from './AdminAdList';
import AdminAdInput from './AdminAdInput';
import AdminLocationChat from './AdminLocationChat';

function AdminPage() {
	  const [activeTab, setActiveTab] = useState('UserCheck');
	  const [sidebarOpen, setSidebarOpen] = useState(true);

	  return (
		 <div>

		        {/* 사이드바 토글 버튼 */}
		        <Button 
		          className="btn btn-light" 
		          style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000 }} 
		          onClick={() => setSidebarOpen(!sidebarOpen)}>
		          <List size={24} />
		        </Button>


		      <div style={{ height: '100vh', width: '100vw', position: 'absolute', left: sidebarOpen ? '250px' : '0' }}>

		        {/* 사이드 메뉴 */}
		        {sidebarOpen && (
				        <div className="sidebar p-3 bg-light" style={{ width: '250px', height: '100%', position: 'fixed', left: 0, top: 0, zIndex: 1 }}>
				          <h5>메뉴</h5>
				          <hr />
				          <Button className={`btn btn-light w-100 ${activeTab === 'UserCheck' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('UserCheck')}><Person /> 사용자 정보</Button>
				          <Button className={`btn btn-light w-100 ${activeTab === 'ReportCheck' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('ReportCheck')}><Search /> 신고 유저</Button>
				          <Button className={`btn btn-light w-100 ${activeTab === 'StopUser' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('StopUser')}>정지된 유저</Button>
				          <Button className={`btn btn-light w-100 ${activeTab === 'DeleteUser' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('DeleteUser')}>삭제한 사용자</Button>
				          <br /><hr />
				          <Button className={`btn btn-light w-100 ${activeTab === 'Partner' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('Partner')}>파트너 관리</Button>
				          <br />
				          <Button className={`btn btn-light w-100 ${activeTab === 'LocationAd' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('LocationAd')}>광고 관리</Button>
				          <br /><hr />
				          <Button className={`btn btn-light w-100 ${activeTab === 'LocationChat' ? 'btn-primary' : ''}`} onClick={() => setActiveTab('LocationChat')}><House /> 지역 채팅방 목록</Button>
				        </div>
				      )}

		        {/* 컨텐츠 영역 */}
		        <div className="content-container" style={{ padding: '20px', width: '100%' }}>
		          {activeTab === 'UserCheck' && (<><br/><br/><AdminUserCheck /><hr /><AdminUserFind /> </>)}
		          {activeTab === 'ReportCheck' && (<><br/><br/><AdminReportCheck /><hr /><AdminUserFind /> </>)}
		          {activeTab === 'StopUser' && (<><br/><br/><AdminStopUser /><hr /><AdminUserFind /> </>)}
		          {activeTab === 'DeleteUser' && (<><br/><br/><AdminDeleteUser /></>)}
		          {activeTab === 'Partner' && (<><br/><br/><AdminPartnerInput /><AdminPartnerList /></>)}
		          {activeTab === 'LocationAd' && (<><br/><br/><AdminAdInput /><AdminAdList /></>)}
		          {activeTab === 'LocationChat' && (<><br/><br/><AdminLocationChat /></>)}
		        </div>
		      </div>
		  </div>
		    );
}

export default AdminPage;

