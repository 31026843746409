import { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Modal, ListGroup } from "react-bootstrap";

const AdminAdInput = () => {
  const [adData, setAdData] = useState({
    loWhere: "",
    loAge: "ALL",
    adImage: "",
    pId: "",
    startDate: "",
    endDate: "",
    adPlace: "",
  });

  const [roomList, setRoomList] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

  // 광고 정보 변경 처리
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdData((prev) => ({ ...prev, [name]: value }));
  };

  // 장소 선택 처리
  const handleSelect = (roomChoice) => {
    setAdData((prevData) => ({
      ...prevData,
      loWhere: roomChoice,
    }));
    setFilteredResults([]);  // 선택 후 필터 결과 초기화
  };

  // 광고 등록 처리
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token"); // 인증 토큰

      const response = await axios.post(`${apiUrl}/AdminAdInput`, {
        ...adData,
        token,
      });

      console.log("Success:", response.data);
      alert("광고가 성공적으로 등록되었습니다!");

      // 입력 필드 초기화
      setAdData({
        loWhere: "",
        loAge: "ALL",
        adImage: "",
        pId: "",
        startDate: "",
        endDate: "",
        adPlace: "",
      });
    } catch (error) {
      console.error("Error:", error);
      alert("광고 등록 중 오류가 발생했습니다.");
    }
  };

  // roomList 가져오기
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${apiUrl}/AdminAdRoomList`, {
          token,
        });

        // roomList가 배열인지 확인
        if (Array.isArray(response.data.roomList)) {
          setRoomList(response.data.roomList);
        } else {
          console.error("roomList는 배열이어야 합니다:", response.data);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("리스트를 받아오던 중 오류가 발생했습니다.");
      }
    };
    fetchRooms();
  }, []);

  // 광고 대상 지역 필터링
  useEffect(() => {
    if (adData.loWhere !== "") {
      const filtered = roomList.filter((room) =>
        room.toLowerCase().includes(adData.loWhere.toLowerCase())
      );
      setFilteredResults(filtered);
    } else {
      setFilteredResults([]);
    }
  }, [adData.loWhere, roomList]);

  const [view, setView] = useState(false);
  const handleView = () => {
    setView(!view);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Button variant="success" onClick={() => handleView()}>
        광고 등록 화면 {view ? "닫기" : "열기"}
      </Button>
      {view && (
        <Modal show={view} onHide={handleView}>
          <Modal.Header closeButton>
            <Modal.Title>광고 정보 등록</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block", width: "80%", maxWidth: "500px" }}>
              <Form onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>
                {/* 광고 대상 지역 (동) */}
                <Form.Group className="mb-3">
                  <Form.Label>광고 대상 지역 (동)</Form.Label>
                  <Form.Control
                    type="text"
                    name="loWhere"
                    placeholder="예: 서울특별시 강남구 역삼동"
                    value={adData.loWhere}
                    onChange={handleChange}
	            autocomplete="off"
                  />
                  {filteredResults.length > 0 && (
                    <ListGroup
                      style={{
                        position: "absolute",
                        width: "74.5%",
                        zIndex: 1000,
                        background: "white",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {filteredResults.map((roomChoice, index) => (
                        <ListGroup.Item
                          key={index}
                          onClick={() => handleSelect(roomChoice)}
                          style={{ cursor: "pointer" }}
                        >
                          {roomChoice}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </Form.Group>

                {/* 기타 폼 요소들 */}
                <Form.Group className="mb-3">
                  <Form.Label>광고 대상 연령대</Form.Label>
                  <Form.Select name="loAge" value={adData.loAge} onChange={handleChange}>
                    <option value="ALL">전체</option>
                    <option value="1014">10~14세</option>
                    <option value="1519">15~19세</option>
                    <option value="2024">20~24세</option>
                    <option value="2529">25~29세</option>
                    <option value="3034">30~34세</option>
                    <option value="3539">35~39세</option>
                    <option value="4044">40~44세</option>
                    <option value="4549">45~49세</option>
                    <option value="5054">50~54세</option>
                    <option value="5559">55~59세</option>
                    <option value="6064">60~64세</option>
                    <option value="6569">65~69세</option>
                  </Form.Select>
                </Form.Group>

                {/* 광고 장소 기재 */}
                <Form.Group className="mb-3">
                  <Form.Label>광고 장소</Form.Label>
                  <Form.Control
                    type="text"
                    name="place"
                    placeholder="예: 선릉역 신동궁 감자탕"
                    value={adData.place}
                    onChange={handleChange}
	            autocomplete="off"
                  />
                </Form.Group>


                {/* 광고 이미지 URL */}
                <Form.Group className="mb-3">
                  <Form.Label>광고 이미지 URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="adImage"
                    placeholder="광고 이미지 URL"
                    value={adData.adImage}
                    onChange={handleChange}
	            autocomplete="off"
                  />
                </Form.Group>

                {/* 광고 오너 ID */}
                <Form.Group className="mb-3">
                  <Form.Label>파트너 ID (광고 오너)</Form.Label>
                  <Form.Control
                    type="text"
                    name="pId"
                    placeholder="파트너 ID"
                    value={adData.pId}
                    onChange={handleChange}
	            autocomplete="off"
                  />
                </Form.Group>

                {/* 광고 기간 선택 */}
                <Form.Group className="mb-3">
                  <Form.Label>광고 기간 선택</Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={adData.startDate}
                      onChange={handleChange}
                      min={new Date().toISOString().split("T")[0]} // 오늘 이후만 선택 가능
                    />
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={adData.endDate}
                      onChange={handleChange}
                      min={adData.startDate} // 시작 날짜 이후만 선택 가능
                    />
                  </div>
                </Form.Group>

                <Button variant="outline-success" type="submit">
                  광고 등록
                </Button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default AdminAdInput;

