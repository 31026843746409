import {Container, Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {PinAngle} from 'react-bootstrap-icons';
import ClassView from './ClassView';
import {useState, useEffect} from 'react';

const fontSizeSmall={fontSize: '0.9rem', width: '100%', margin:'0', padding:'3px', textAlign: 'center'};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '1px solid #999999'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px', width: '38%', paddingRight: 0};
const fontSmall = {fontSize:'0.6rem'};
const marginTop2 = {marginTop: '2px', textAlign:'left', height: '48%'};
const MyClassList = [
	['신림동 No.3', '달리기', '2024-04-30', '19:00', '5','5', '1', 'd68822f5d60372eeb8485ffcd8a9b68a'],
	['금호4가동 No.11', '독서모임!', '2024-05-14', '13:00', '3', '6','3', '0f1237033482f8fc2a818efdca981c5d'],
	['금호4가동 No.3', '커피한잔', '2024-05-14', '22:00', '1', '3','hobby', '0e99fa16aad57b04fd02a6196cb3b158'],
];

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
};



function checkType(MyType){
        let category = 0;

        switch(MyType) {
                case 'Amity': category = "/ClassAmity.png"; break;
                case 'Exercise': category = "/ClassExercise.png"; break;
                case 'Hobby': category = "/ClassHobby.png"; break;
                case 'Etc': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;

        }   
        return category;
}




function OftenVisitLocationClass( {subClass, myUid} ) { 

        //최근 3개의 데이터 돌려서 추출하기
        const [subClassData, setSubClassData] = useState([]);

        useEffect(() => {
                console.log('Props in MyLocationClass :', subClass);
                if(subClass && subClass.length >0){
                        console.log('class Details sub!!!!!!!!!!!!!:', subClass);
                        setSubClassData(subClass);
                }   
                console.log('classDetails Out:', subClass);
        },[subClass]);


	const ViewSubClassList = subClassData.map((v, index)=> (
		<Col xs={5}  key={index} style={classAll} onClick={() => handleViewClick(v.classId)}>
		    <Row style={{margin:0, marginRight: '10px'}}>
			<Row style={{margin:0}}>
			</Row>
			<Row style={{...classBody, margin:0}}>
			    {myUid === v.cOpenUser 
				    ? <div style={{position: 'relative'}}>
					<img
				    	    src='/crown.png'
				    	    alt='Crown Icon'
				    	    style={{position:'absolute', right:'5px', top:'5px', zIndex: 1, width: '15px', height: '15px'}}
				    	/>
				    </div>
				    : null
			    }
			    <div style={marginTop2}>
				<Image src={checkType(v.ctype)} fluid rounded style={{padding: '5px 15px'}}/>
			    </div>
			    <div style={{fontWeight:'bold',  textAlign: 'left', padding: '0 5px', fontSzie: '12px', whiteSpace: 'normal'}}>{v.cTitle}</div>
			    <div style={{textAlign: 'left', padding: '0 2px'}}><Image style={{width: '15px'}} src='/userIcon.png' /> {v.cNowUser.length+1}/{v.cTotalNum} </div>
			    <div style={{color: 'gray', textAlign: 'left', padding: '0 2px', margin: '10px 0'}}>{formatDate(v.cOpenDate)}</div>
			</Row>
		    </Row>
		</Col>
	));

	//######################클릭하면 보여주는 부분#################
        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

	const handleCloseView = () => {
		 setViewClassCheck(!viewClassCheck);
	};
	//############################################################


	return(
	<div style={{padding: '0 10px'}}>
	<Row style={{margin:0, marginTop: '3rem'}}>
	    <Col xs={9}>
		<h4><b>자주가는 동네 모임</b></h4>
	    </Col>
	    <Col xs={3} style={{fontSize: '0.7rem'}}>
		    <Link to='/myOftenLoClass'>
		        <Button variant="link" style={{...fontSizeSmall, textAlign: 'right'}}>
			    <Image src='/rightArrow.png' />
		        </Button>
		    </Link>
	    </Col>
	</Row>
	    <div style={{ overflowX: "auto", whiteSpace: "nowrap", scrollbarWidth: "none", msOverflowStyle: "none",  WebkitOverflowScrolling: "touch", marginBottom: '3rem'}}>
		<div style={{display: "inline-flex", width: '100%'}}>
                    {ViewSubClassList.length > 0 ?(
                            ViewSubClassList.concat(
                                    Array.from({ length: Math.max(0, 3 - ViewSubClassList.length) }).map((_, index) => (
                                            <div key={`empty-${index}`} style={classAll}></div>
                                    ))
                            )
                    ):(
                            <div style={{paddingTop: '2rem', textAlign: 'center', width: '100%'}}>
                            <Image src='/noClass.png' style={{width: '55px'}} />
                            <p style={{marginTop: '1rem', color: '#aaaaaa'}}>모임을 만들어보세요</p>
                            </div>
                    )}
		</div>
	    </div>
        {viewClassCheck && (
                <div>
                    <ClassView classId={viewClassId} onCloseView={handleCloseView}/>
                </div>
        )}

	</div>
	);
}

export default OftenVisitLocationClass;
