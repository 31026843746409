import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dropdown, Table, Form } from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { getStatusLabel, formatSignUpTime, formatPhoneNumber } from './utils'

function AdminUserCheck() {
  const [userInfo, setUserInfo] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [usersPerPage, setUsersPerPage] = useState(50); // 초기값: 페이지 당 사용자 수
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  //검색 상태 변수
  const [searchField, setSearchField] = useState('uid');
  const [searchValue, setSearchValue] = useState('');

  //새로고침
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchUserInfo(currentPage, usersPerPage);
  }, [currentPage, usersPerPage, refresh]);

  const fetchUserInfo = async (page, limit, field = '', value = '') => {
    try {
      const response = await axios.post(`${apiUrl}/UserCheck`, {
        token: token,
        page: page,
        limit: limit,
	token: token,
	searchField: field,
	searchValue: value,
      });
      setUserInfo(response.data.userInfo);
      setTotalUsers(response.data.totalUsers); // 총 가입인원
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const totalPages = Math.ceil(totalUsers / usersPerPage); // 총 페이지 수

  const handleUsersPerPageChange = (limit) => {
    setUsersPerPage(limit);
    setCurrentPage(1); // 페이지를 첫 번째로 초기화
  };


  const handleSearch = () => {
    let mappedSearchValue = searchValue;

    if (searchValue === '정상') {
      mappedSearchValue = 'Y';
    } else if (searchValue === '경고') {
      mappedSearchValue = 'W';
    } else if (searchValue === '블록') {
      mappedSearchValue = '정지';
    }

    // mainlo와 sublo를 동시에 검색할 수 있도록 처리
    fetchUserInfo(currentPage, usersPerPage, searchField, mappedSearchValue);
  };

  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h5>
	  총 가입인원: {totalUsers}
	  <ArrowRepeat style={{fontSize:'1.2rem', marginLeft: '10px'}} onClick={() => {
		  console.log('아이콘 클릭');
		  setRefresh(prev => prev + 1);
	  }} />


	</h5> {/* 좌측 상단 */}

      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
        <Form.Select value={searchField} style={{fontSize: '0.8rem'}} onChange={(e) => setSearchField(e.target.value)}>
          <option value="uid">UID</option>
          <option value="birth">생일</option>
          <option value="sex">성별</option>
          <option value="nname">닉네임</option>
          <option value="message">메시지</option>
          <option value="phone">핸드폰</option>
          <option value="rname">이름</option>
          <option value="location">동네</option>
          <option value="state">상태</option>
        </Form.Select>
        <Form.Control
          type="text"
          placeholder="검색어 입력"
          value={searchValue}
	  style={{fontSize: '0.8rem'}}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button variant="primary" style={{fontSize: '0.8rem', width: '35%'}} onClick={handleSearch}>검색</Button>
      </div>

	  <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{fontSize: '0.8rem'}}>
            페이지당 {usersPerPage}개 표시
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item key={limit} onClick={() => handleUsersPerPageChange(limit)}>
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> {/* 우측 상단 */}
      </div>

      <div className="table-container">
        <Table striped bordered hover style={{fontSize: '0.6rem'}}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd' }}>UID</th>
              <th style={{ border: '1px solid #ddd' }}>생일</th>
              <th style={{ border: '1px solid #ddd' }}>성별</th>
              <th style={{ border: '1px solid #ddd' }}>닉네임</th>
              <th style={{ border: '1px solid #ddd' }}>메시지</th>
              <th style={{ border: '1px solid #ddd' }}>가입일</th>
              <th style={{ border: '1px solid #ddd' }}>핸드폰</th>
              <th style={{ border: '1px solid #ddd' }}>이름</th>
	      <th style={{ border: '1px solid #ddd' }}>우리동네</th>
	      <th style={{ border: '1px solid #ddd' }}>자주가는동네</th>
              <th style={{ border: '1px solid #ddd' }}>상태</th>
	      <th style={{ border: '1px solid #ddd' }}>상태 변경일</th>
            </tr>
          </thead>
          <tbody>
            {userInfo.map((user) => (
              <tr key={user._id}>
                <td style={{ border: '1px solid #ddd' }}>{user.uid}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.birth}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.sex === 'F' ? '여성' : '남성'}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.nname}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.message}</td>
                <td style={{ border: '1px solid #ddd' }}>{new Date(user.time).toLocaleString()}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.phone}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.rname}</td>
		<td style={{ border: '1px solid #ddd' }}>{user.mainlo}</td>
		<td style={{ border: '1px solid #ddd' }}>{user.sublo}</td>
                <td style={{ border: '1px solid #ddd' }}>{getStatusLabel(user.state)}</td>
		<td style={{ border: '1px solid #ddd' }}>{user.sChangeDate ? (
			new Date(user.sChangeDate).toLocaleString()) : ""}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            style={{
              margin: '0 5px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#f8f9fa',
              color: currentPage === index + 1 ? '#fff' : '#000',
            }}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default AdminUserCheck;

