import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dropdown, Table, Form, Col, Row } from 'react-bootstrap';

function AdminLocationChat() {
  const [chatRooms, setChatRooms] = useState([]);
  const [totalRooms, setTotalRooms] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [roomsPerPage, setRoomsPerPage] = useState(50);
  const [searchLocation, setSearchLocation] = useState(''); // "동 명" 검색 상태
  const [searchAge, setSearchAge] = useState(''); // "연령대" 검색 상태
  const [filteredRooms, setFilteredRooms] = useState([]); // 필터링된 방 상태
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchChatRooms(currentPage, roomsPerPage);
  }, [currentPage, roomsPerPage]);

  const fetchChatRooms = async (page, limit) => {
    try {
      const response = await axios.post(`${apiUrl}/ChatRoomCheck`, {
        page,
        limit,
        token,
      });

      const rooms = response.data.chatRooms;

      // 각 채팅방의 총 인원 수 가져오기
      const roomIds = rooms.map((room) => room.rid);
      const membersResponse = await axios.post(`${apiUrl}/ChatRoomMembersCount`, {
        roomIds,
        token,
      });

      const membersCountMap = membersResponse.data.membersCount; // { rid1: 10, rid2: 5, ... }
      
      const updatedRooms = rooms.map((room) => ({
        ...room,
        totalMembers: membersCountMap[room.rid] || 0,
      }));

      setChatRooms(updatedRooms);
      setTotalRooms(response.data.totalRooms);
    } catch (error) {
      console.error('Error fetching chat rooms:', error);
    }
  };


// 검색 버튼 클릭 시 Express에서 데이터를 새로 가져옴
const filterRooms = async () => {
  try {
    const response = await axios.post(`${apiUrl}/ChatRoomCheck`, {
      page: 1, // 검색 시 첫 페이지로 초기화
      limit: roomsPerPage,
      token,
      searchLocation,
      searchAge,
    });

    const rooms = response.data.chatRooms;

    // 각 채팅방의 총 인원 수 가져오기
    const roomIds = rooms.map((room) => room.rid);
    const membersResponse = await axios.post(`${apiUrl}/ChatRoomMembersCount`, {
      roomIds,
      token,
    });

    const membersCountMap = membersResponse.data.membersCount;

    const updatedRooms = rooms.map((room) => ({
      ...room,
      totalMembers: membersCountMap[room.rid] || 0,
    }));

    setChatRooms(updatedRooms);
    setTotalRooms(response.data.totalRooms);
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
  } catch (error) {
    console.error("Error fetching filtered chat rooms:", error);
  }
};

  const totalPages = Math.ceil(totalRooms / roomsPerPage);

  const handleRoomsPerPageChange = (limit) => {
    setRoomsPerPage(limit);
    setCurrentPage(1);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h5>총 채팅방 수: {totalRooms}</h5>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ fontSize: '0.8rem' }}>
            페이지당 {roomsPerPage}개 표시
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item key={limit} onClick={() => handleRoomsPerPageChange(limit)}>
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
  <Form inline>
<Row>
    <Col>
        {/* 검색 필터 */}
          <Form.Group>
            <Form.Control
              type="text"
              value={searchLocation}
              onChange={(e) => setSearchLocation(e.target.value)}
              placeholder="동 명 검색"
              style={{ fontSize: '0.8rem' }}
            />
          </Form.Group>
    </Col>
    <Col>
          <Form.Group style={{ marginLeft: '20px' }}>
            <Form.Control
              type="text"
              value={searchAge}
              onChange={(e) => setSearchAge(e.target.value)}
              placeholder="연령대 검색 (예: 20-24세 검색 시 2024, 전체채팅방 검색 시 ALL)"
              style={{ fontSize: '0.8rem' }}
            />
          </Form.Group>
	  <p style={{color: 'red'}}>
	      * 연령대는 5살 단위로 끊어집니다. (예: 2024, 2529, 3034)
	  </p>
    </Col>
    <Col xs={2}>
          <Button
            variant="primary"
            onClick={filterRooms}
            style={{ marginLeft: '10px', fontSize: '0.8rem', width: '100%' }}
          >
            검색
          </Button>
    </Col>
  </Row>
</Form>

      <div className="table-container">
        <Table striped bordered hover style={{ fontSize: '0.6rem' }}>
          <thead>
            <tr>
              <th>Room ID</th>
              <th>채팅방 이름</th>
              <th>동 명</th>
              <th>연령대</th>
              <th>생성일</th>
              <th>총 인원 수</th>
            </tr>
          </thead>
          <tbody>
            {(filteredRooms.length > 0 ? filteredRooms : chatRooms).map((room) => (
              <tr key={room.rid}>
                <td>{room.rid}</td>
                <td>{room.roomName}</td>
                <td>{room.loWhere}</td>
                <td>{room.loAge === 'ALL' ? '전체' : room.loAge}</td>
                <td>{new Date(room.createAt).toLocaleString()}</td>
                <td>{room.totalMembers}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            style={{
              margin: '0 5px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#f8f9fa',
              color: currentPage === index + 1 ? '#fff' : '#000',
            }}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default AdminLocationChat;

