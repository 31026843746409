import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Image, Button, Dropdown, Table, Form, Modal } from 'react-bootstrap';

function AdminAdList() {
  const [locationAds, setLocationAds] = useState([]);
  const [totalAds, setTotalAds] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage, setAdsPerPage] = useState(50);
  const [searchLoWhere, setSearchLoWhere] = useState("");
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchLocationAds(currentPage, adsPerPage, searchLoWhere);
  }, [currentPage, adsPerPage]);

  const fetchLocationAds = async (page, limit, loWhere) => {
    try {
      const response = await axios.post(`${apiUrl}/LocationAdCheck`, {
        page,
        limit,
        loWhere,
	place,
        token,
	excludeExpired,
      });
      
      setLocationAds(response.data.adInfo);

      console.log('response.data.adInfo: ',response.data.adInfo);

      setTotalAds(response.data.totalAds);
    } catch (error) {
      console.error('Error fetching location ads:', error);
    }
  };

  const totalPages = Math.ceil(totalAds / adsPerPage);

  const handleAdsPerPageChange = (limit) => {
    setAdsPerPage(limit);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchLoWhere(e.target.value);
  };

  const handleCheckChange = (e) => {
    setExcludeExpired(e.target.checked); // 체크 상태를 `excludeExpired`에 설정
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchLocationAds(1, 10, searchLoWhere); // 기본 page와 limit 설정 (필요 시 수정)
  };

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const [changeAdView, setChangeAdView] = useState(false);
  const [loWhere, setLoWhere] = useState('');
  const [place, setPlace] = useState('');
  const [loAge, setLoAge] = useState();
  const [adImage, setAdImage] = useState('');
  const [pId, setPId] = useState('');
  const [_id, set_id] = useState('');
  const [newPId, setNewPId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleChangeAd = (_id, loWhere, loAge, place, adImage, pId, startDate, endDate) => {
	setChangeAdView(true);

	set_id(_id);
	setLoWhere(loWhere);
	setPlace(place);
	setLoAge(loAge);
	setStartDate(startDate);
	setEndDate(endDate);
	setAdImage(adImage);
	setPId(pId);
  };

  const [deletePId, setDeletePId] = useState('');
  const [deleteImage, setDeleteImage] = useState('');
  const [viewDelete, setViewDelete] = useState(false);
  const handleDeleteAd = async (pId, pImage) => {
	setDeletePId(pId);
	setDeleteImage(pImage);
	setViewDelete(true);
	console.log('viewDelete : ', viewDelete);
  };

  const handleDeleteAdSubmit = async() => {
	const response = await axios.post(`${apiUrl}/DeleteAd`, {
		token,
		deleteImage
	});
  };

  const handleAdChange = async () => {
    try{

	const newAdData = {
		loWhere,
		place,
		loAge,
		adImage,
		startDate,
		endDate,
		...(newPId && { pId: newPId })  // newPId가 있을 때만 pId를 추가
	};

	const response = await axios.post(`${apiUrl}/changeAd`, {
		token,
		newAdData,
		_id
	});
	
	if (response.status === 200) {
		alert('광고가 성공적으로 변경되었습니다.');
	}

    } catch (error) {
	    console.error(error);
	    alert('광고 변경 중 오류가 발생했습니다.');
    }
  };

  const handleClose = () => {
	  setChangeAdView(false);
	  setViewDelete(false);
  };


  const [excludeExpired, setExcludeExpired] = useState(false);
  const handleExcludeExpiredChange = (e) => {
	  setExcludeExpired(e.target.checked);
  };


  return (
    <div style={{marginTop: '2rem'}}>

{viewDelete && (
     <Modal show={viewDelete} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>광고 정보 삭제</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
	정말 광고를 삭제하시겠습니까?
	<div style={{width: '100%', textAlign: 'center'}}>
	  <Image src={deleteImage} style={{width: '80%', border: 'solid 1px gray'}} />
	</div>
       </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => handleDeleteAdSubmit()}>광고 삭제하기</Button>
	<Button variant="secondary" onClick={() => handleClose()}>닫기</Button>
      </Modal.Footer>
    </Modal>
   
)}

{changeAdView && (

    <Modal show={changeAdView} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>광고 정보 수정</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>광고 대상 지역 (동)</Form.Label>
            <Form.Control 
              type="text" 
              value={loWhere} 
              onChange={(e) => setLoWhere(e.target.value)} 
              placeholder="예: 강남구 역삼동" 
            />
          </Form.Group>

   
          <Form.Group className="mb-3">
            <Form.Label>광고 대상 연령대</Form.Label>
            <Form.Select value={loAge} onChange={(e) => setLoAge(e.target.value)}>
              <option value="ALL">전체</option>
              <option value="1014">10~14세</option>
              <option value="1519">15~19세</option>
              <option value="2024">20~24세</option>
              <option value="2529">25~29세</option>
              <option value="3034">30~34세</option>
              <option value="3539">35~39세</option>
              <option value="4044">40~44세</option>
              <option value="4549">45~49세</option>
              <option value="5054">50~54세</option>
              <option value="5559">55~59세</option>
              <option value="6064">60~64세</option>
              <option value="6569">65~69세</option>
            </Form.Select>
          </Form.Group>

       <Form.Group className="mb-3">
            <Form.Label>장소 기재</Form.Label>
            <Form.Control 
              type="text" 
              value={place} 
              onChange={(e) => setPlace(e.target.value)} 
              placeholder="예: 선릉역 신동궁 감자탕" 
            />
          </Form.Group>



	<Form.Group className="mb-3">
		<Form.Label>광고 기간 선택</Form.Label>
		<div style={{ display: "flex", gap: "10px" }}> 
		{/* 시작 날짜 선택 */}
		<Form.Control
			type="date"
			name="startDate"
			value={startDate}
			onChange={(e) => setStartDate(e.target.value)}
		/>
		{/* 종료 날짜 선택 */}
		<Form.Control
			type="date"
			name="endDate"
			value={endDate}
			onChange={(e) => setEndDate(e.target.value)}
			min={startDate} // 시작 날짜 이후만 선택 가능
		/>
		</div>
	</Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>광고 이미지 URL</Form.Label>
            <Form.Control 
              type="text" 
              value={adImage} 
              onChange={(e) => setAdImage(e.target.value)}
              placeholder="광고 이미지 URL" 
            />
          </Form.Group>
	  <div style={{width: '100%', textAlign: 'center'}}>
	    <Image src={adImage} style={{width: '80%', marginBottom: '1rem'}} />
	  </div>
          <Form.Group className="mb-3">
            <Form.Label>파트너 ID (광고 오너)</Form.Label>
            <Form.Control 
              type="text" 
              value={newPId ? newPId : pId} 
              onChange={(e) => setNewPId(e.target.value)}
              placeholder="파트너 ID" 
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
	<Button variant="success" onClick={handleAdChange}>광고 수정</Button>
        <Button variant="secondary" onClick={handleClose}>닫기</Button>
      </Modal.Footer>
    </Modal>


	  )}

	  <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h5>총 광고 수: {totalAds}</h5>
	<Form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
	        <Form.Control
	          type="text"
	          placeholder="loWhere 검색"
	          value={searchLoWhere}
	          onChange={handleSearchChange}
	          style={{ width: "200px", fontSize: "0.8rem" }}
	        />
	
		<Form.Check
		  type="checkbox"
	 	  id="excludeExpired"
		  label="만료된 광고 제외"
		  checked={excludeExpired}
		  onChange={(e) => handleCheckChange(e)}
		/>
	
		<Button variant="primary" type="submit" style={{fontSize: '0.8rem'}}>
		   검색
		</Button>
	</Form>

        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ fontSize: '0.8rem' }}>
            페이지당 {adsPerPage}개 표시
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item key={limit} onClick={() => handleAdsPerPageChange(limit)}>
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="table-container">
        <Table striped bordered hover style={{ fontSize: '0.6rem' }}>
          <thead>
            <tr>
              <th>지역</th>
              <th>연령대</th>
	      <th> 광고장소 </th>
              <th>광고 이미지</th>
              <th>파트너 이름</th>
	      <th>광고 등록일 </th>
	      <th>광고 시작일</th>
	      <th>광고 종료일</th>
	      <th>수정</th>
	      <th>삭제</th>
            </tr>
          </thead>
          <tbody style={{fontSize: '0.8rem'}}>
            {locationAds && locationAds.map((ad) => (
              <tr key={ad._id}>
                <td>{ad.loWhere}</td>
                <td>{ad.loAge}</td>
		<td>{ad.place}</td>
                <td>
                  <Image src={ad.adImage}
		    style={{maxWidth: '150px'}}
                    onClick={() => openImageInNewTab(ad.adImage)}
                  />
                </td>
                <td>{ad.pName}</td>
		<td>{new Date(ad.createdAt).toLocaleString()}</td>
		<td>{ad.startDate}</td>
		<td>{ad.endDate}</td>
		<td><Button 
		    onClick={()=>handleChangeAd(ad._id, ad.loWhere, ad.loAge, ad.place, ad.adImage, ad.pId, ad.startDate, ad.endDate)} 
		    style={{fontSize: '0.8rem'}}
		    variant="outline-secondary">수정하기</Button></td>
		<td><Button onClick={()=>handleDeleteAd(ad.pId, ad.adImage)}
		    style={{fontSize: '0.8rem'}}
		    variant="outline-secondary">삭제하기</Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            style={{
              margin: '0 5px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#f8f9fa',
              color: currentPage === index + 1 ? '#fff' : '#000',
            }}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default AdminAdList;

