import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dropdown, Table, Form, Modal } from 'react-bootstrap';
import {ArrowRepeat} from 'react-bootstrap-icons';

function AdminPartnerList() {
  const [partnerInfo, setPartnerInfo] = useState([]);
  const [totalPartners, setTotalPartners] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [partnersPerPage, setPartnersPerPage] = useState(50); // 초기 페이지당 데이터 개수
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');
  const [refresh, setRefresh] = useState(0);
  //검색 상태 변수
  const [searchField, setSearchField] = useState('pId');
  const [searchValue, setSearchValue] = useState('');


  useEffect(() => {
    fetchPartnerInfo(currentPage, partnersPerPage);
  }, [currentPage, partnersPerPage, refresh]);

  const fetchPartnerInfo = async (page, limit, field='' , value='') => {
    try {

      console.log('field: ',field);
      console.log('value: ',value);

      const response = await axios.post(`${apiUrl}/PartnerCheck`, {
        page,
        limit,
        token,
	searchField: field,
	searchValue: value,
      });

      setPartnerInfo(response.data.partnerInfo);
      setTotalPartners(response.data.totalPartners);
    } catch (error) {
      console.error('Error fetching partner info:', error);
    }
  };

  const totalPages = Math.ceil(totalPartners / partnersPerPage); // 총 페이지 수

  const handlePartnersPerPageChange = (limit) => {
    setPartnersPerPage(limit);
    setCurrentPage(1); // 페이지를 1로 초기화
  };


  const handleSearch = () => {
    console.log('searchField: ', searchField);
    console.log('searchValue: ', searchValue);
    fetchPartnerInfo(currentPage, partnersPerPage, searchField, searchValue);
  };

  const [modifyControl, setModifyControl] = useState(false);
  const [deleteControl, setDeleteControl] = useState(false);
  const [pId, setPId] = useState('');
  const [pName, setPName] = useState('');
  const [pPhone, setPPhone] = useState('');
  const [pEmail, setPEmail] = useState('');
  const [pNume, setPNume] = useState('');

  const handlePartnerInfo = async( type, tpId, tpName, tpPhone, tpEmail, tpNume ) => {
    setPId(tpId);
    setPName(tpName);
    setPPhone(tpPhone);
    setPEmail(tpEmail);
    setPNume(tpNume);

    // 상태 변경 후 다음 이벤트 루프에서 실행되도록 대기
    await new Promise(resolve => setTimeout(resolve, 0));

    switch(type) {
	  case "Modify" :
		console.log('modify');
		setModifyControl(!modifyControl);
	  	break;
	  case "Delete" :
		console.log('delete');
		setDeleteControl(!deleteControl);
	  	break;
	  default : 
	  	break;
    }
  };

  const handleClose = () => {
	  setPId('');
	  setPName('');
	  setPPhone('');
	  setPEmail('');
	  setPNume('');

	  setModifyControl(false);
	  setDeleteControl(false);
  };

  const handleDeletePartnerSubmit = async() => {
      try{
        const response = await axios.post(`${apiUrl}/DeletePartner`, {
                token,
                pId
        });
	alert('파트너가 삭제되었습니다.');
	setRefresh(prev=>prev+1);
      } catch (error) {
	console.error(error);
	alert('파트너 삭제 중 오류 발생');
      }
  };

  const handlePartnerModify = async () => {
    try{          
                  
        const newPartnerData = {
                pId,
		pName,
		pPhone,
		pEmail,
		pNume,
        };        
                  
        const response = await axios.post(`${apiUrl}/changePartner`, {
                token,
                newPartnerData
        });        
                
        if (response.status === 200) {
                alert('파트너 정보가 성공적으로 변경되었습니다.');
		setRefresh(prev => prev + 1);
		handleClose();
        }
          
    } catch (error) {
            console.error(error);
            alert('파트너 정보 변경 중 오류가 발생했습니다.');
    }       
  };

  return (
    <div style={{marginTop: '2rem'}}>
	  <hr />
      {/* 검색 & 페이지당 개수 선택 */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h5>
	  총 파트너 수: {totalPartners}
	  <ArrowRepeat style={{fontSize:'1.2rem', marginLeft: '10px'}} onClick={() => setRefresh(prev => prev + 1)} />
	</h5> {/* 좌측 상단 */}

      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
        <Form.Select value={searchField} style={{fontSize: '0.8rem'}} onChange={(e) => setSearchField(e.target.value)}>
          <option value="pId"> 파트너 ID</option>
          <option value="pName">사업자 명</option>
          <option value="pPhone">핸드폰</option>
          <option value="pEmail">이메일</option>
          <option value="pNume">사업자번호</option>
        </Form.Select>
        <Form.Control
          type="text"
          placeholder="검색어 입력"
          value={searchValue}
          style={{fontSize: '0.8rem'}}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button variant="primary" style={{fontSize: '0.8rem', width: '35%'}} onClick={handleSearch}>검색</Button>
      </div>
	

        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ fontSize: '0.8rem' }}>
            페이지당 {partnersPerPage}개 표시
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item key={limit} onClick={() => handlePartnersPerPageChange(limit)}>
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> {/* 우측 상단 */}
      </div>

      {/* 테이블 */}
      <div className="table-container">
        <Table striped bordered hover style={{ fontSize: '0.6rem' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd' }}>파트너 ID</th>
              <th style={{ border: '1px solid #ddd' }}>사업자 명</th>
              <th style={{ border: '1px solid #ddd' }}>핸드폰</th>
              <th style={{ border: '1px solid #ddd' }}>이메일</th>
              <th style={{ border: '1px solid #ddd' }}>사업자번호</th>
              <th style={{ border: '1px solid #ddd' }}>가입일</th>
	      <th style={{ border: '1px solid #ddd' }}>수정하기</th>
	      <th style={{ border: '1px solid #ddd' }}>삭제하기</th>
            </tr>
          </thead>
          <tbody>
            {partnerInfo.map((partner) => (
              <tr key={partner._id}>
                <td style={{ border: '1px solid #ddd' }}>{partner.pId}</td>
                <td style={{ border: '1px solid #ddd' }}>{partner.pName}</td>
                <td style={{ border: '1px solid #ddd' }}>{partner.pPhone}</td>
                <td style={{ border: '1px solid #ddd' }}>{partner.pEmail}</td>
                <td style={{ border: '1px solid #ddd' }}>{partner.pNume}</td>
                <td style={{ border: '1px solid #ddd' }}>{new Date(partner.createdAt).toLocaleString()}</td>
		<td style={{ border: '1px solid #ddd' }}>
		    <Button variant="outline-secondary" size="sm" style={{fontSize: '0.6rem'}} 
		        onClick={()=>handlePartnerInfo("Modify", partner.pId, partner.pName, partner.pPhone, partner.pEmail, partner.pNume)} >
			수정하기
		    </Button>
		</td>
		<td style={{ border: '1px solid #ddd' }}>
		    <Button variant="outline-secondary" size="sm" style={{fontSize: '0.6rem'}}
		        onClick={()=>handlePartnerInfo("Delete", partner.pId)} >
		        삭제하기
		    </Button>
		</td>
	      </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* 페이지네이션 */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            style={{
              margin: '0 5px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#f8f9fa',
              color: currentPage === index + 1 ? '#fff' : '#000',
            }}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
	{deleteControl && (
	     <Modal show={deleteControl} onHide={handleClose}>
	      <Modal.Header closeButton>
	        <Modal.Title>파트너 정보 삭제</Modal.Title>
	      </Modal.Header>
	      <Modal.Body style={{textAlign: 'center'}}>
	        정말 광고를 삭제하시겠습니까? 
	       </Modal.Body>
	      <Modal.Footer>
	        <Button variant="success" onClick={() => handleDeletePartnerSubmit()}>광고 삭제하기</Button>
	        <Button variant="secondary" onClick={() => handleClose()}>닫기</Button>
	      </Modal.Footer>
	    </Modal>
	)}
	{modifyControl && (
	    <Modal show={modifyControl} onHide={handleClose}>
	      <Modal.Header closeButton>
	        <Modal.Title>광고 정보 수정</Modal.Title>
	      </Modal.Header>
	      <Modal.Body>
	        <Form>    
	          <Form.Group className="mb-3">
	            <Form.Label>파트너 이름</Form.Label>
	            <Form.Control
	              type="text" 
	              value={pName}
	              onChange={(e) => setPName(e.target.value)}
	              placeholder="예: 파트너 이름"
	            />
	          </Form.Group>

	          <Form.Group className="mb-3">
	            <Form.Label>파트너 핸드폰번호</Form.Label>
	            <Form.Control
	              type="number" 
	              value={pPhone}
	              onChange={(e) => setPPhone(e.target.value)}
	              placeholder="예: 01012345678"
	            />
	          </Form.Group>

	          <Form.Group className="mb-3">
	            <Form.Label>파트너 이메일</Form.Label>
	            <Form.Control
	              type="text" 
	              value={pEmail}
	              onChange={(e) => setPEmail(e.target.value)}
	              placeholder="예: innerdong@gmail.com"
	            />
	          </Form.Group>

	          <Form.Group className="mb-3">
	            <Form.Label>사업자번호</Form.Label>
	            <Form.Control
	              type="text" 
	              value={pNume}
	              onChange={(e) => setPNume(e.target.value)}
	              placeholder="예: 815-81-00527"
	            />
	          </Form.Group>


		</Form>
	      </Modal.Body>
	      <Modal.Footer>
	        <Button variant="success" onClick={handlePartnerModify}>파트너 수정</Button>
	        <Button variant="secondary" onClick={handleClose}>닫기</Button>
	      </Modal.Footer>
	    </Modal>

	)}
      </div>
    </div>
  );
}

export default AdminPartnerList;
